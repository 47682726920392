<template>
  <div>
    <projects></projects>
  </div>
</template>

<script>
  import Projects from '../../components/Projects/Projects';

  export default {
    name: 'Projects',
    components: {
      projects: Projects
    },
    data() {
      return {

      };
    }
  };
</script>
